import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { backendUrl } from '../../../rootUrl';

const infoUrl = backendUrl + "/api/product/home-info/";

const initialState = {
    isLoading: false,
    isSuccess: false,
    mainInfo: [],
    countryPhones: [
        ['Turkmenistan', 'tm', '993']
    ],
    countryPhonesFlags: [{
        iso2: 'tm',
        src: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f2.svg'
    }],
    workingHours: [
        { day: 0, start_time: "09:00", end_time: "18:00" },
        { day: 1, start_time: "09:00", end_time: "18:00" },
        { day: 2, start_time: "09:00", end_time: "18:00" },
        { day: 3, start_time: "09:00", end_time: "18:00" },
        { day: 4, start_time: "09:00", end_time: "18:00" },
        { day: 5, start_time: "09:00", end_time: "18:00" },
        { day: 6, start_time: "10:00", end_time: "18:00" },

    ]
}

export const getInfo = createAsyncThunk(
    "info/getInfo",
    async (arg, { rejectWithValue }) => {
        try {
            // const config = {
            //     headers: {
            //         'Content-Type': 'application/json',
            //     }
            // };
            const { data } = await axios.get(infoUrl);
            return data;

        } catch (err) {
            if (!err.response) {
                throw err
            }
            rejectWithValue(err.response.data)
        }
    }
)

const infoSlice = createSlice({
    name: "info",
    initialState,
    reducers: {
        setCountryPhones: (state, action) => {
            state.countryPhones = action.payload
        },
        setCountryPhonesFlags: (state, action) => {
            state.countryPhonesFlags = action.payload
        }
    },
    extraReducers: {
        [getInfo.pending]: (state) => {
            state.isLoading = true;
        },
        [getInfo.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.mainInfo = payload['data'];
            state.isSuccess = true;
            if (payload.data && payload.data.countryPhones && payload.data.countryPhones.length > 0) {
                // debugger
                state.countryPhones = payload.data.countryPhones.map((item) => {
                    return [item.title, item.iso2, item.code]
                })
                state.countryPhonesFlags = payload.data.countryPhones.map((item) => {
                    return {
                        iso2: item.iso2,
                        src: backendUrl + item.src
                    }
                })
                state.workingHours = payload.data.workingHours
            }
            else {
                state.countryPhones = [['Turkmenistan', 'tm', '993']]
                state.countryPhonesFlags = [{
                    iso2: 'tm',
                    src: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f1f9-1f1f2.svg'
                }]
            }
        },
        [getInfo.rejected]: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
        }
    }
})

export default infoSlice.reducer;