import logo from "../img/logo1923.png";

const Loader = () => {
  const lang = localStorage.getItem("userLanguage");
  const returnTitle = () => {
    if (lang === "tm") return "Maglumatlar ýüklenýär";
    else return "Идет загрузка";
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 h-full w-full z-50 bg-primaryBackground flex justify-center items-center flex-col">
      <img src={logo} alt="logo" className="w-[40%] sm:w-[30%] md:w-[20%] lg:w-[10%] 2xl:w-[5%]"/>
      <h1 className="text-3xl font-mono uppercase animate-pulse text-primary mt-2">{returnTitle()}</h1>
    </div>
  );
};

export default Loader;
