import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import PersistProvider from "./store/providers/persist-provider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-international-phone/style.css";
import { store } from "./store/store";
// import SnowFall from "react-snowfall";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistProvider>
      {/* <SnowFall snowflakeCount={200}  style={{
        position:"fixed",
        zIndex:5
      }}/> */}

      <App />
    </PersistProvider>
  </Provider>
);
